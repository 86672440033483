import React, {useEffect, useState} from "react";

import App from "../../Global/App";
import API from "../../Global/API";
import User from "../../Models/User";
import Logger from "../../Global/Logger";
import Settings from "../../Global/Settings";
import Employee from "../../Models/Employee";
import {useAuth} from "../../Global/Auth";
import Formatter from "../../Global/Formatter";
import InputPhone from "../../Components/Input/InputPhone";
import TextEditor from "../../Components/Input/TextEditor";
import ModelSearch from "../../Components/Input/ModelSearch";
import DialogHeading from "../../Components/Typography/DialogHeading";
import SectionHeading from "../../Components/Typography/SectionHeading";

import Box from "@mui/material/Box";
import dayjs from "dayjs";
import Radio from "@mui/material/Radio";
import Alert from "@mui/material/Alert";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Snackbar from "@mui/material/Snackbar";
import MenuItem from "@mui/material/MenuItem";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import FormLabel from "@mui/material/FormLabel";
import RadioGroup from "@mui/material/RadioGroup";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import FormControlLabel from "@mui/material/FormControlLabel";

/**
 * The default content for offer letters.
 *
 * @type {string}
 */
const defaultOfferLetter = '<p><span style="background-color: #ffff6b;">Pay Rate:</span> Your base rate is for a minimum of 24 hours/week. Reliant does have higher rates for staff who work contracts. Contracts require specific scheduling requirements and performance (Contact your scheduler for details).  Once you commit to a schedule, the expectation is that you work your schedule.</p>\n' +
    '<p><span style="background-color: #ffff6b;">OT/Holiday Pay</span>: Our work week starts on Sunday and ends on Saturday. Overtime is considered time worked greater than 40 hours during the work week. Overtime hours will be paid at your base rate x1.5. Holiday pay (except for all Kane facilities) is also paid at your base rate x1.5. Reliant’s holiday schedule can be found on online at www.ReliantStaffing.com under the employee tab.</p>\n' +
    '<p><span style="background-color: #ffff6b;">Referral Fee</span>: All employees are eligible for a referral fee of $250 if the following criteria are met. The referring employee must be active and working an average of 32 hours a week with fewer than 7 attendance points during the first 13 weeks the referred employee begins to work. The new employee that was referred must have met the signing bonus criteria listed above. If either the employee or referred employee does not meet the requirements during the first 13 weeks of the referred employee start date, the referral fee is forfeited.</p>';


/**
 * The default "Pay Notes" content (based off of the Intuit export).
 *
 * @type {string}
 */
const defaultPayNotes =
    "Hourly rate: \n" +
    "Overtime: \n" +
    "Holiday: \n" +
    "Pay method: \n" +
    "Deductions: \n" +
    "Contributions: \n" +
    "Time off: "


/**
 * The various shift types available.
 *
 * @type {string[]}
 */
const shiftTypes = [
    'Morning',
    'Afternoon',
    'Evening',
    'Weekend'
];


/**
 * Various localizations for the shift types.
 *
 * @type {Object}
 */
const localeOptions = {
    'Evening': 'Night'
}


/**
 * EmployeeForm component.
 *
 * @returns {*}
 * @constructor
 */
const EmployeeForm = (props) => {
    const {
        open,               // {Boolean} Indicates whether the form is currently opened.
        record,             // {Object} The associated database record for the form.
        onSave,             // {Function} The post-save callback for reloading layouts, tables, etc.
        onClose,            // {Function} The modal close callback to fire once the user exits the form.
    } = props;

    const {user} = useAuth();

    /**
     * The initial state of the form.
     *
     * @type {Object}
     */
    const defaultValues = {
        ...Employee.getInstance(record),

        // Auto-assign the current user.
        assigneeId: !record || !record.id ?
            user.id : record.assigneeId,

        // Create the default offer letter content.
        offerLetter: !record || !record.id ? defaultOfferLetter : record.offerLetter,

        // Include the default pay notes.
        payNotes: !record || !record.id ? defaultPayNotes : record.payNotes,
    };


    /**
     * Various form state values.
     */
    const [error, setError] = useState('');
    const [states, setStates] = useState([]);
    const [fields, setFields] = useState(defaultValues);
    const [statuses, setStatuses] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [isSubmitted, setSubmitted] = useState(false);
    const [specialties, setSpecialties] = useState([]);
    const [helixRegions, setHelixRegions] = useState([]);


    /**
     * Various form values that require default values.
     */
    const [stateId, setStateId] = useState(defaultValues.stateId || '');
    const [statusId, setStatusId] = useState(defaultValues.statusId || '');
    const [specialtyId, setSpecialtyId] = useState(defaultValues.specialtyId || '');


    /**
     * The employees' shift preferences.
     *
     * @type {any}
     */
    const shiftPreferencesJson = JSON.parse(fields.shiftPreferences || '[]')


    /**
     * The employee's type of employment.
     *
     * @type {string}
     */
    const employmentType = (fields.isPerDiem || fields.isContract) ? (
        fields.isPerDiem ?
            'perDiem' :
            'contract'
    ) : '';


    /**
     * Whether the employee is enrolled in Helix.
     *
     * @type {string}
     */
    const enrolledInHelix = !!fields.isHelixOptIn ? 'yes' : 'no';


    /**
     * Load any additional details on mount.
     */
    useEffect(() => {
        doLoad();
    }, []);


    /**
     * Synchronize the related fields.
     */
    useEffect(() => {
        setValue('stateId', stateId);
    }, [stateId]);

    useEffect(() => {
        setValue('statusId', statusId);
    }, [statusId]);

    useEffect(() => {
        setValue('specialtyId', specialtyId);
    }, [specialtyId]);


    /**
     * Whether the current user is the HR user.
     *
     * @type {boolean}
     */
    const bIsHR = (/^hr@reliantstaffing.net$/i)
        .test(user.emailAddress || '');


    /**
     * Returns a localization option, if applicable.
     *
     * @param key
     * @returns {*}
     */
    const getLocale = (key) => {
        return localeOptions[key] || key;
    }


    /**
     * Loads all ancillary data for managing employees.
     *
     * @returns {Promise<void>}
     */
    const doLoad = async () => {
        setLoading(true);

        await Promise.all([
            getStates(),
            getStatuses(),
            getSpecialties(),
            getHelixRegions()
        ]);

        setLoading(false);
    };


    /**
     * Performs the save via the API.
     */
    const doSave = async () => {
        setSubmitted(true);

        // Validate required fields.
        if (
            !statusId ||
            !fields.lastName ||
            !fields.firstName ||
            !fields.phoneNumber ||
            !specialtyId ||
            !fields.emailAddress
        ) {
            return setError('Missing one or more required fields.');
        }

        setLoading(true);

        // Handle unique validation on the frontend.
        const existing = await API.get('employees', {
            $filter: `emailAddress eq {${fields.emailAddress}}` + (fields.id ? ` and id ne {${fields.id}}` : ``)
        });

        if (existing && existing.length) {
            setLoading(false);
            return setError('This email address is already in use.');
        }

        const payload = {
            ...fields,

            stateId,
            statusId,
            specialtyId,

            shiftPreferences: JSON.stringify(shiftPreferencesJson)
        };

        // Remove relations that might interfere with the backend.
        if (payload.hasOwnProperty('status')) {
            delete payload['status'];
        }

        if (payload.hasOwnProperty('specialty')) {
            delete payload['specialty'];
        }

        const response = fields.id ?
            await API.put(`employee/${fields.id}`, payload) :

            // If we're creating new, let's go ahead and place a highlight on the record.
            await API.post('employees', {
                ...payload,
                isHighlighted: true
            });

        setLoading(false);

        if (response.status && response.status === 'error') {
            return setError(response.message);
        }

        setError('');
        setLoading(false);

        if (onSave) {
            onSave(response);
        }
    };


    /**
     * Updates a particular form value.
     *
     * @param key
     * @param value
     */
    const setValue = (key, value) => {
        Logger.debug(`[EmployeeForm] Updated "${key}" to "${value}"...`);

        setFields({
            ...fields,
            [key]: value
        });
    };


    /**
     * Loads all available Helix regions from the API.
     *
     * @returns {Promise<void>}
     */
    const getHelixRegions = async () => {
        if(!App.isModuleEnabled('trueHelix')) {
            return;
        }

        setHelixRegions(
            await API.get('helix-regions')
        );
    };


    /**
     * Loads all available states from the API.
     *
     * @returns {Promise<void>}
     */
    const getStates = async () => {
        const states = await API.get('states', {
            $top: 100,
            $orderby: `name asc`,
        });

        setStates(states);

        if (states.length && !stateId) {
            const defaultState = states.find(state => state.name === Settings.defaultState) || states[0];

            setStateId(defaultState.id);
        }
    };


    /**
     * Loads all available specialties from the API.
     *
     * @returns {Promise<void>}
     */
    const getSpecialties = async () => {
        const specialties = await API.get('specialties', {
            $filter: 'isDeleted eq {0}'
        })

        setSpecialties(specialties);

        if (specialties.length && !specialtyId) {
            setSpecialtyId(specialties[0].id);
        }
    };


    /**
     * Loads all employee statuses from the API.
     *
     * @returns {Promise<void>}
     */
    const getStatuses = async () => {
        const statuses = await API.get('statuses', {
            $filter: `modelType eq {Employee}`
        });

        setStatuses(statuses);

        if (statuses.length && !statusId) {
            statuses.map(status => {
                if (status.isDefault) {
                    setStatusId(status.id);
                }
            })
        }
    };


    /**
     * Allows the use of the tab button within the form context.
     *
     * @param event
     */
    const stopPropagationForTab = (event) => {
        if (event.key === "Tab") {
            event.stopPropagation();
        }
    };


    /**
     * Closes the error snackbar.
     */
    const handleErrorClose = () => {
        setError('');
    };


    /**
     * Updates the employment type.
     *
     * @param event
     */
    const handleEmploymentTypeChange = (event) => {
        const value = event.target.value;

        if(value === 'perDiem') {
            setFields({
                ...fields,
                isPerDiem: 1,
                isContract: 0
            });
        }
        else {
            setFields({
                ...fields,
                isPerDiem: 0,
                isContract: 1
            });
        }
    }


    /**
     * Updates the shift preference.
     *
     * @param type
     * @param value
     */
    const handleShiftPreference = (type, value) => {
        setValue('shiftPreferences', JSON.stringify({
            ...shiftPreferencesJson,
            [type]: value
        }));
    }


    /**
     * Updates the Helix mobile opt-in status.
     *
     * @param event
     */
    const handleHelixOptInChange = (event) => {
        const value = event.target.value;

        if(value === 'yes') {
            setFields({
                ...fields,
                isHelixOptIn: 1,
            });
        }
        else {
            setFields({
                ...fields,
                isHelixOptIn: 0,
            });
        }
    }

    return (
        <>
            <Dialog
                open={open}
                scroll={'body'}
                onClose={onClose}
                maxWidth={'lg'}
                onKeyDown={stopPropagationForTab}
            >
                <DialogHeading
                    title={'Employee Details'}
                    noMargin
                />
                <DialogContent>
                    <Box className={'columns__1'}>
                        <SectionHeading title={'Contact Information'}/>
                        <Box className={'columns__3'}>
                            <TextField
                                label={'First Name'}
                                value={fields.firstName}
                                error={isSubmitted && !fields.firstName}
                                required
                                disabled={isLoading}
                                onChange={event => setValue('firstName', event.target.value)}
                            />
                            <TextField
                                label={'Last Name'}
                                value={fields.lastName}
                                error={isSubmitted && !fields.lastName}
                                required
                                disabled={isLoading}
                                onChange={event => setValue('lastName', event.target.value)}
                            />
                            <TextField
                                label={'Email Address'}
                                value={fields.emailAddress}
                                error={isSubmitted && !fields.emailAddress}
                                required
                                disabled={isLoading}
                                onChange={event => setValue('emailAddress', event.target.value)}
                            />
                        </Box>
                        <Box className={'columns__4'}>
                            <FormControl>
                                <InputLabel
                                    error={isSubmitted && !specialtyId}
                                    required
                                >Specialty</InputLabel>
                                <Select
                                    label={'Specialty'}
                                    value={specialtyId || ''}
                                    error={isSubmitted && !specialtyId}
                                    required
                                    disabled={isLoading}
                                    onChange={event => setSpecialtyId(event.target.value)}
                                >
                                    {specialties.map((specialty) =>
                                        <MenuItem
                                            key={specialty.id}
                                            value={specialty.id}
                                            children={specialty.name}
                                        />
                                    )}
                                </Select>
                            </FormControl>
                            <InputPhone
                                label={'Phone Number'}
                                value={fields.phoneNumber}
                                error={isSubmitted && !fields.phoneNumber}
                                required
                                disabled={isLoading}
                                onChange={event => setValue('phoneNumber', event.target.value)}
                            />
                            <TextField
                                label={'SSN #'}
                                value={fields.ssn}
                                disabled={isLoading}
                                onChange={event => setValue('ssn', Formatter.lastFour(event.target.value))}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">***-**-</InputAdornment>,
                                }}
                            />
                            <DatePicker
                                label={'Birthdate'}
                                value={fields.birthDate ? dayjs(fields.birthDate) : null}
                                disabled={isLoading}
                                onChange={event => setValue('birthDate', event ? event.format('YYYY-MM-DD') : '')}
                            />
                            <DatePicker
                                label={'Hire Date'}
                                value={fields.hireDate ? dayjs(fields.hireDate) : null}
                                disabled={isLoading}
                                onChange={event => setValue('hireDate', event ? event.format('YYYY-MM-DD') : '')}
                            />
                            <DatePicker
                                label={'Termination Date'}
                                value={fields.terminationDate ? dayjs(fields.terminationDate) : null}
                                disabled={isLoading}
                                onChange={event => setValue('terminationDate', event ? event.format('YYYY-MM-DD') : '')}
                            />
                            <ModelSearch
                                model={User}
                                label={'Assignee'}
                                value={fields.assigneeId}
                                onChange={value => setValue('assigneeId', value)}
                                renderLabel={(option) => !option ? '' : option.displayName}
                                filterQuery={query => `isDeleted eq {0} and displayName eq {${query}}`}
                            />
                            <FormControl>
                                <InputLabel
                                    error={isSubmitted && !statusId}
                                    required
                                >Status</InputLabel>
                                <Select
                                    label={'Status'}
                                    value={statusId || ''}
                                    error={isSubmitted && !statusId}
                                    required
                                    disabled={isLoading}
                                    onChange={event => setStatusId(event.target.value)}
                                >
                                    {statuses.map(status =>
                                        <MenuItem
                                            key={status.id}
                                            value={status.id}
                                            children={status.name}
                                            disabled={status.name === 'DNR' && !bIsHR}
                                        />
                                    )}
                                </Select>
                            </FormControl>
                        </Box>

                        {App.isModuleEnabled('trueHelix') && (
                            <Box className={'columns__3'}>
                                <FormControl>
                                    <InputLabel>True-Helix Region</InputLabel>
                                    <Select
                                        label={'True-Helix Region'}
                                        value={fields.helixRegionId}
                                        disabled={isLoading}
                                        onChange={event => setValue('helixRegionId', event.target.value)}
                                        children={
                                            helixRegions.map(region =>
                                                <MenuItem
                                                    key={region.id}
                                                    value={region.helixId}
                                                    children={region.name}
                                                />
                                            )
                                        }
                                    />
                                </FormControl>
                            </Box>
                        )}
                    </Box>
                </DialogContent>
                <DialogContent>
                    <Box className={'columns__1'}>
                        <InputLabel className={'form__heading'}>Location Information</InputLabel>
                        <Box className={'columns__2'}>
                            <TextField
                                label={'Address Line 1'}
                                value={fields.addressLine1}
                                disabled={isLoading}
                                onChange={event => setValue('addressLine1', event.target.value)}
                            />
                            <Box className={'columns__2_3'}>
                                <TextField
                                    label={'Address Line 2'}
                                    value={fields.addressLine2}
                                    disabled={isLoading}
                                    onChange={event => setValue('addressLine2', event.target.value)}
                                />
                                <TextField
                                    label={'Apartment #'}
                                    value={fields.apartmentNumber}
                                    disabled={isLoading}
                                    onChange={event => setValue('apartmentNumber', event.target.value)}
                                />
                            </Box>
                        </Box>
                        <Box className={'columns__3'}>
                            <TextField
                                label={'City'}
                                value={fields.city}
                                disabled={isLoading}
                                onChange={event => setValue('city', event.target.value)}
                            />
                            <FormControl>
                                <InputLabel>State</InputLabel>
                                <Select
                                    label={'State'}
                                    value={stateId || ''}
                                    disabled={isLoading}
                                    onChange={event => setStateId(event.target.value)}
                                >
                                    {states.map((state) =>
                                        <MenuItem
                                            key={state.id}
                                            value={state.id}
                                            children={state.name}
                                        />
                                    )}
                                </Select>
                            </FormControl>
                            <TextField
                                label={'Postal Code'}
                                value={fields.postalCode}
                                disabled={isLoading}
                                onChange={event => setValue('postalCode', event.target.value)}
                            />
                        </Box>
                    </Box>
                </DialogContent>
                <DialogContent>
                    <Box className={'columns__2_3'}>
                        <Box>
                            <Box className={'columns__1'}>
                                <InputLabel className={'form__heading'}>
                                    Pay Details
                                </InputLabel>
                                <Box className={'d-flex__start'}>
                                    <FormControl sx={{minWidth: '32%'}} className={'mr__4'}>
                                        <InputLabel>Hourly Rate</InputLabel>
                                        <OutlinedInput
                                            label={'Hourly Rate'}
                                            value={fields.payRate}
                                            disabled={isLoading}
                                            onChange={event => setValue('payRate', event.target.value)}
                                            startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                        />
                                    </FormControl>
                                    <FormControl className={'mr__5'}>
                                        <FormLabel>Employment Type</FormLabel>
                                        <RadioGroup
                                            row
                                            value={employmentType}
                                            onChange={handleEmploymentTypeChange}
                                        >
                                            <FormControlLabel
                                                value={'perDiem'}
                                                label={'Per Diem'}
                                                control={
                                                    <Radio
                                                        disabled={isLoading}
                                                    />
                                                }
                                            />
                                            <FormControlLabel
                                                value={'contract'}
                                                label={'Contract'}
                                                control={
                                                    <Radio
                                                        disabled={isLoading}
                                                    />
                                                }
                                            />
                                        </RadioGroup>
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel>Enrolled in Helix?</FormLabel>
                                        <RadioGroup
                                            row
                                            value={enrolledInHelix}
                                            onChange={handleHelixOptInChange}
                                        >
                                            <FormControlLabel
                                                value={'no'}
                                                label={'No'}
                                                control={
                                                    <Radio
                                                        disabled={isLoading}
                                                    />
                                                }
                                            />
                                            <FormControlLabel
                                                value={'yes'}
                                                label={'Yes'}
                                                control={
                                                    <Radio
                                                        disabled={isLoading}
                                                    />
                                                }
                                            />
                                        </RadioGroup>
                                    </FormControl>
                                </Box>
                                <TextField
                                    rows={4}
                                    value={fields.payNotes}
                                    label="Please provide any additional details..."
                                    disabled={isLoading}
                                    onChange={event => setValue('payNotes', event.target.value)}
                                    multiline
                                    fullWidth
                                />
                            </Box>
                        </Box>

                        <Box>
                            <InputLabel className={'form__heading mb__3'}>
                                Shift Preferences
                            </InputLabel>
                            <Box className={'pl__3'}>
                                {shiftTypes.map((shift, index) => (
                                    <Box key={`EmployeeForm-ShiftType-${index}`}>
                                        <FormControlLabel
                                            label={getLocale(shift)}
                                            control={
                                                <Checkbox
                                                    disabled={isLoading}
                                                    onChange={event => handleShiftPreference(shift, event.target.checked)}
                                                    defaultChecked={!!shiftPreferencesJson[shift]}
                                                />
                                            }
                                        />
                                    </Box>
                                ))}
                            </Box>
                        </Box>
                    </Box>
                </DialogContent>
                <DialogContent>
                    <Box className={'columns__1'}>
                        <InputLabel className={'form__heading'}>Offer Letter</InputLabel>
                        <TextEditor
                            value={fields.offerLetter}
                            onChange={value => setValue('offerLetter', value)}
                        />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={doSave}
                        children={'Save'}
                        disabled={isLoading}
                    />
                    <Button
                        color={'error'}
                        onClick={onClose}
                        children={'Close'}
                        disabled={isLoading}
                    />
                </DialogActions>
            </Dialog>

            <Snackbar
                open={!!error}
                onClose={handleErrorClose}
                autoHideDuration={6000}
            >
                <Alert
                    onClose={handleErrorClose}
                    children={error}
                    severity={'error'}
                />
            </Snackbar>
        </>
    );
};

export default EmployeeForm;